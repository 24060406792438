import {default as BSModal} from 'react-bootstrap/Modal';
import {Button} from 'react-bootstrap'

export default function Modal(props: any) {
  
  const {show, onHide, title, size, centered, enforceFocus, centeredTitle, id, hideCloseButton} = props

  return (
    <BSModal
    id={id}
    dialogClassName={props.dialogClassName}
    enforceFocus={enforceFocus} restoreFocus={false} size={size||'md'} centered={centered!==undefined ? centered:true} show={show} onHide={onHide}>
      { centeredTitle &&
        <BSModal.Header className="modal__title-center">
          { !hideCloseButton &&
            <i style={{top: 15, right: 15}} onClick={onHide} className="cursor-pointer position-absolute bi bi-x-circle-fill fs-2 text-white"></i>
          }
          <BSModal.Title>{title}</BSModal.Title>
        </BSModal.Header>
      }
      <BSModal.Body>
        {
          !centeredTitle &&
          <>
            <i style={{top: 15, right: 15}} onClick={onHide} className="cursor-pointer position-absolute bi bi-x-circle-fill fs-2 text-danger"></i>
            <h3 className="pb-1 modal__title mt-3 mb-6">{title}</h3>
          </>
        }
        {props.children}
      </BSModal.Body>
    </BSModal>
  )
}

export function ConfirmModal(props: any) {
  const {show, onHide, title, content,
    confirmText, cancelText, confirmVariant, onConfirm
  } = props

  return (
    <BSModal restoreFocus={false} centered show={show} onHide={onHide}>
      <BSModal.Body >
        <h3 className="mt-3 mb-6">{title || 'ยืนยันการทำรายการ?'}</h3>
        <p>{content}</p>
        <div className="row-center mt-6">
          <Button onClick={onHide} variant="light">{cancelText || 'ยกเลิก'}</Button>
          <Button onClick={onConfirm} className="ms-5" variant={confirmVariant||'danger'}>{confirmText || 'ยืนยัน'}</Button>
        </div>
      </BSModal.Body>
    </BSModal>
  )
}
