import {FC, useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {MenuInner} from './header-menus'
import {useLocation} from 'react-router-dom'

const getPageTitle = (pathname: string) => {
  let title = ''
  if (pathname === '/patient') {
    title = 'รายการคนไข้'
  } else if (pathname === '/patient/create') {
    title = 'เพิ่มคนไข้'
  } else if (pathname.match(/patient\/[0-9]+\/edit/)) {
    title = 'แก้ไขคนไข้'
  } else if (pathname.match(/patient\/[0-9]+/)) {
    title = 'รายละเอียดคนไข้'
  } else if (pathname === '/setting') {
    title = 'อื่นๆ'
  } else if (pathname === '/setting/doctor') {
    title = 'รายการทันตแพทย์'
  } else if (pathname === '/setting/doctor/create') {
    title = 'เพิ่มทันตแพทย์'
  } else if (pathname.match(/setting\/doctor\/[0-9]+\/edit/)) {
    title = 'แก้ไขทันตแพทย์'
  } else if (pathname === '/setting/treatment-category') {
    title = 'รายการหมวดการรักษา'
  } else if (pathname === '/setting/treatment-category/create') {
    title = 'เพิ่มหมวดการรักษา'
  } else if (pathname.match(/setting\/treatment-category\/[0-9]+\/edit/)) {
    title = 'แก้ไขหมวดการรักษา'
  } else if (pathname === '/setting/treatment-item') {
    title = 'รายการรักษา/แล็บ/สินค้า'
  } else if (pathname === '/setting/treatment-item/create') {
    title = 'เพิ่มรายการรักษา/แล็บ/สินค้า'
  } else if (pathname.match(/setting\/treatment-item\/[0-9]+\/edit/)) {
    title = 'แก้ไขรายการรักษา/แล็บ/สินค้า'
  }

  return title
}

const Header: FC = () => {
  const {config} = useLayout()
  const location = useLocation()
  useEffect(() => {
    updateDOM(config)
  }, [config])

  return (
    <div
      className='
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-stretch
        fw-semibold
        px-2 px-lg-0
    '
      id='kt_app_header_menu'
      data-kt-menu='true'
    >
      <h2 className="d-flex fw-bold align-items-center">{getPageTitle(location.pathname)}</h2>
      {/*<MenuInner />*/}
    </div>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.header?.default?.fixed?.desktop) {
    document.body.setAttribute('data-kt-app-header-fixed', 'true')
  }

  if (config.app?.header?.default?.fixed?.mobile) {
    document.body.setAttribute('data-kt-app-header-fixed-mobile', 'true')
  }

  if (config.app?.header?.default?.stacked) {
    document.body.setAttribute('data-kt-app-header-stacked', 'true')
  }

  const appHeaderDefaultStickyEnabled = config.app?.header?.default?.sticky?.enabled
  let appHeaderDefaultStickyAttributes: {[attrName: string]: string} = {}
  if (appHeaderDefaultStickyEnabled) {
    appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky?.attributes as {
      [attrName: string]: string
    }
  }

  const appHeaderDefaultMinimizeEnabled = config.app?.header?.default?.minimize?.enabled
  let appHeaderDefaultMinimizeAttributes: {[attrName: string]: string} = {}
  if (appHeaderDefaultMinimizeEnabled) {
    appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize?.attributes as {
      [attrName: string]: string
    }
  }

  setTimeout(() => {
    const headerElement = document.getElementById('kt_app_header')
    // header
    if (headerElement) {
      const headerAttributes = headerElement
        .getAttributeNames()
        .filter((t) => t.indexOf('data-') > -1)
      headerAttributes.forEach((attr) => headerElement.removeAttribute(attr))

      if (appHeaderDefaultStickyEnabled) {
        for (const key in appHeaderDefaultStickyAttributes) {
          if (appHeaderDefaultStickyAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultStickyAttributes[key])
          }
        }
      }

      if (appHeaderDefaultMinimizeEnabled) {
        for (const key in appHeaderDefaultMinimizeAttributes) {
          if (appHeaderDefaultMinimizeAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultMinimizeAttributes[key])
          }
        }
      }
    }
  }, 0)
}

export {Header}
