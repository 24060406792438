export const reactSelectValue = (opts: Array<any>, id: any, key:string = 'value') => opts.find(o => o[key] === id) || null;
export const reactSelectMultiValue = (opts: Array<any>, ids: any, key:string = 'value') => {
  let values:Array<{label: string, value: any}> = [];

  (ids||[]).map((id: any)=>{
    let opt = opts.find(o => o[key] === id)
    if (!opt) opt = {value: id, label: id}

    values.push(opt)
  })

  return values
}

export const inputOnlyNumber = (e: any) => {
  if (!(/^[0-9]$/i.test(e.key) || e.metaKey || e.which <= 0 || e.which == 8 || e.keyCode == 9)) {
    e.preventDefault()
  }
}
export const inputOnlyPrice = (e: any) => {
  if (!(/^[0-9]$/i.test(e.key) || e.key === '.' || e.metaKey || e.which <= 0 || e.which == 8 || e.keyCode == 9 )) {
    e.preventDefault()
  }
}
