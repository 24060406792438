import Modal from '../../components/Modals'
import {Button, Row, Col, Form, Table} from 'react-bootstrap'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import {useState, useEffect} from 'react'
import api from '../../utils/Api'
import {DoctorSchedule} from './components/Schedules'
import moment from 'moment'

export default function DoctorIframe () {
  // const [searchParams] = useSearchParams()
  // const id = searchParams.get('name')
  // const token = searchParams.get('token')
  const {slug} = useParams()
  const [data, setData] = useState<any>([])
  const [reviewCompleted, setReviewCompleted] = useState(false)

  useEffect(()=>{
    api.request('iframe/doctor', (r,s)=>{
      if (!s) return
        setData(r)
    }, {slug, startDate: moment().startOf('month').toDate(), endDate: moment().endOf('month').toDate()})
  }, [])

  return (
    <>
      <DoctorSchedule data={data} />
    </>
  )
}
