import Modal from '../../components/Modals'
import {Button, Row, Col, Form, Table} from 'react-bootstrap'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import {useState} from 'react'
import api from '../../utils/Api'

const width = 'auto'
const height = 'auto'
const starSize = 24

const textRate = [
  'ปรับปรุง',
  'พอใช้',
  'ปานกลาง',
  'ดี',
  'ดีมาก'
]

export default function CustomerReview () {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const token = searchParams.get('token')
  const [form, setForm] = useState<any>({id, token})
  const [reviewCompleted, setReviewCompleted] = useState(false)

  return (
    <Modal
      size="md"
      show={true}
      centered
      centeredTitle
      hideCloseButton
      title={"รีวิวการให้บริการ"}
    >
      <Row>
        <Col lg={12} className="d-flex flex-column">
          <h5 className="mb-0 me-2" style={{width}}>1. ท่านได้รับบริการที่ใส่ใจจากพนักงาน</h5>
          <Rating
            onClick={(rate)=>{
              setForm({...form, staff: rate})
            }}
            size={starSize}
          />
        </Col>
        <Col lg={12} style={{height}} className="d-flex flex-column mt-3">
          <h5 className="mb-0 me-2" style={{width}}>2. ท่านได้รับบริการที่ใส่ใจจากทันตแพทย์</h5>
          <Rating
            onClick={(rate)=>{
              setForm({...form, doctor: rate})
            }}
            size={starSize}
          />
        </Col>
        <Col lg={12} style={{height}} className="d-flex flex-column mt-3">
          <h5 className="mb-0 me-2" style={{width}}>3. ท่านพึงพอใจกับสิ่งอำนวยความสะดวก</h5>
          <Rating
            onClick={(rate)=>{
              setForm({...form, facility: rate})
            }}
            size={starSize}
          />
        </Col>
        <Col lg={12} style={{height}} className="d-flex flex-column mt-3">
          <h5 className="mb-0 me-2" style={{width}}>4. ท่านมั่นใจในความสะอาดและมาตรฐานความปลอดภัย</h5>
          <Rating
            onClick={(rate)=>{
              setForm({...form, safety: rate})
            }}
            size={starSize}
          />
        </Col>
        <Col lg={12} style={{height}} className="d-flex flex-column mt-3">
          <h5 className="mb-0 me-2" style={{width}}>5. ท่านพึงพอใจกับภาพรวมการใช้บริการในครั้งนี้</h5>
          <Rating
            onClick={(rate)=>{
              setForm({...form, overview: rate})
            }}
            size={starSize}
          />
        </Col>
        <Col lg={12} className="mt-3">
          <h5 className="">6. ข้อเสนอแนะอื่นๆ</h5>
          <Form.Control
            as="textarea"
            rows={3}
            maxLength={100}
            onChange={(e)=>{
              setForm({...form, comment: e.target.value})
            }}
          />
        </Col>
        <Col lg={12} className="mt-3 d-flex justify-content-center">
         { reviewCompleted ?
          <strong>{
            'ขอขอบคุณสำหรับการรีวิว ❤️'
          }</strong>
           :
          <Button onClick={()=>{
            api.request('user-review', (r, s)=>{
               if (!s) return

               setReviewCompleted(true)
            }, api.form(form), 'POST')
          }}>Submit</Button>
         }
        </Col>
      </Row>
    </Modal>
  )
}
