import {default as ReactDatePicker} from 'react-datepicker'
import InputGroup from 'react-bootstrap/InputGroup';
import {useCallback} from 'react'
import th from 'date-fns/locale/th';

const DatepickerInput = ({ ...props }) => {
  const split = props.value.split(' - ')
  let value = props.value

  if (split.length === 2) {
    if (split[0] === split[1]) {
      value = split[0]
    }
  }

  return <InputGroup style={props.selectsRange?{minWidth: 255}:{}} className={props.inputGroupClassName||"mb-3"}>
  <input type="text" {...props} value={value} readOnly={!props.enableTyping} />
    <InputGroup.Text id="basic-addon2"><i className={'bi bi-'+ (props.iconName||'calendar-event') +' fs-7'}></i></InputGroup.Text>
     </InputGroup>
};

export default function DatePicker(props: any) {
  const name = props.name
  const onChangeHandler = useCallback(
  (date: Date, e: React.SyntheticEvent<any>) => props.onChange(date, e, name),
  [props.onChange, name]
)
  return  (
    <ReactDatePicker
      {...props}
      className={"form-control"}
      onChange={onChangeHandler}
      customInput={<DatepickerInput
          selectsRange={props.selectsRange}
         enableTyping={props.enableTyping} inputGroupClassName={props.inputGroupClassName} iconName={props.iconName} />}
      locale={th}
    />
  )
}
