/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import api from '../../utils/Api'
import {numberFormat} from '../../utils/Functions'
import { Bar } from 'react-chartjs-2';
import {Container, Row, Col, Form, Button, InputGroup,Card, Table} from 'react-bootstrap'
import DatePicker from '../../components/DatePicker'
import _ from 'lodash'
import autocolors from 'chartjs-plugin-autocolors';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function fillRanking(data: any) {
  // Step 1: Create an array of prices with their original indices
  const indexedPrices = data.map((price: any, index: number) => ({ price, index }));

  // Step 2: Sort the array by price in descending order
  indexedPrices.sort((a: any, b: any) => b.price - a.price);

  // Step 3: Assign ranks based on the sorted array
  const ranks = new Array(data.length);
  indexedPrices.forEach((item: any, rank: number) => {
    ranks[item.index] = rank + 1;  // Rank starts from 1 (for highest price)
  });

  return ranks
}

const SalesPerHead: FC = () =>{
  const [chart, setChart] = useState<any>({datasets: [], labels: []})
  const [filter, setFilter] = useState<any>({startDate: new Date, endDate: new Date})
  useEffect(()=>{
    if (!filter.startDate || !filter.endDate) return

    api.request('dashboard/sales-per-head', (r,s)=>{
      setChart(r)
    }, {
      ...filter,
      startDate: moment(filter.startDate).toISOString(),
      endDate: moment(filter.endDate).toISOString(),
    })
  }, [filter])

  const data = {
    labels: chart.labels, // X-axis labels
    datasets: chart.datasets,
  };

  return  (
    <Row className="dashboard__item-container">
      <Col xs={12} className="d-flex justify-content-end align-items-center">
        <Form.Group className="d-inline-flex me-5 mb-3" controlId="withPrevYear">
          <Form.Check
           onChange={(e)=>{
                setFilter({...filter, withPrevYear: e.target.checked?1:0})
           }}
           value={1} type="checkbox" name="withPrevYear" label="Compare to previous year" />
       </Form.Group>
        <div className="dashboard__date">
          <DatePicker onChange={([startDate, endDate]: any)=>{
            setFilter({...filter, startDate, endDate})
          }}
          startDate={filter.startDate}
          endDate={filter.endDate}
          dateFormat="dd/MM/yyyy"
          selectsRange
          />
        </div>
      </Col>
      <Col sm={12} md={12}>
        <h3 className="text-center">Sales per head all branches</h3>
        <Bar data={data} plugins={[autocolors, ChartDataLabels]} options={{
          plugins: {
            colors: {
              enabled: true
            },
            legend: {
              display: false // Disable the legend completely
            },
            autocolors: {
             enabled: true,
             mode: 'data',
             offset: 0
           },
           datalabels: {
            anchor: 'end', // Position the label at the top of the bar
            align: 'end',  // Align the label to the end (above the bar)
            color: 'black', // Label text color
            font: {
              //weight: 'bold',
              size: 12 // Font size
            },
            formatter: function(value, context) {
              return numberFormat(Math.round(value), true, ''); // Show the actual value
            }
          }

          },
          scales: {
            x: {
                stacked: true,
            },
            y: {
             //stacked: true, // Stack the bars on the Y-axis (values for each year)
             beginAtZero: true // Ensure the Y-axis starts at zero
           }
            // y: {
            //     stacked: true,
            //     beginAtZero: true
            // }
        }
        }} />
      </Col>
    </Row>

  )
}


export default SalesPerHead
