// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import doctorTypes from '../../data/DoctorTypes'
import moment from 'moment'
import {Badge} from 'react-bootstrap'
import _ from 'lodash'

export const sections = [
  {
    label: 'Dashboard',
    value: 'dashboard'
  },
  {
    label: 'รายงาน',
    value: 'report'
  },
  {
    label: 'จัดการรักษา',
    value: 'treatment'
  },
  {
    label: 'คนไข้',
    value: 'patient'
  },
  {
    label: 'นัดหมาย',
    value: 'booking'
  },
  {
    label: 'ขายสินค้า',
    value: 'product'
  },
  {
    label: 'CRM',
    value: 'crm'
  },
  {
    label: 'ตารางลงตรวจแพทย์',
    value: 'doctor_schedule'
  },
  {
    label: 'ห้องแล็บ',
    value: 'lab'
  },
  {
    label: 'แบบฟอร์ม',
    value: 'medical_form'
  },
  {
    label: 'บัญชี',
    value: 'accounting'
  },
  {
    label: 'จัดการระบบ',
    value: 'setting'
  },
]

export const permissions = [
  {
    label: 'ดู Dashboard',
    value: 'view_dashboard',
    section: 'dashboard'
  },

  {
    label: 'ดูรายงานเคลม/โอน/ปรับปรุง/ค้าง',
    value: 'view_claim_report',
    section: 'report'
  },
  {
    label: 'ดูรายงานขายสินค้า',
    value: 'view_product_report',
    section: 'report'
  },
  {
    label: 'ดูรายงาน ศล.ร้าน',
    value: 'view_tf_report',
    section: 'report'
  },
  {
    label: 'ดูรายงาน ศล.หมอ',
    value: 'view_df_report',
    section: 'report'
  },
  {
    label: 'ดูรายงานสรุปรายการรักษา',
    value: 'view_treatment_report',
    section: 'report'
  },
  {
    label: 'ดูรายงานยกเลิกใบเสร็จพร้อมเหตุผล',
    value: 'view_canceled_receipt_report',
    section: 'report'
  },
  {
    label: 'ดูรายงานคนไข้',
    value: 'view_patient_report',
    section: 'report'
  },
  {
    label: 'ดูรายงานสรุปรายการรักษาแยกหมวดการรักษา',
    value: 'view_treatment_by_cat_report',
    section: 'report'
  },
  {
    label: 'ดูรายงาน  Hi Touch',
    value: 'view_hi_touch_report',
    section: 'report'
  },
  {
    label: 'ดูรายงานเงินฝากคงเหลือ',
    value: 'view_wallet_report',
    section: 'report'
  },
  {
    label: 'ดูรายงานประมาณการรายได้ ทันตแพทย์',
    value: 'view_income_report',
    section: 'report'
  },


  {
    label: 'ดูข้อมูลจัดการรักษา',
    value: 'view_treatment',
    section: 'treatment'
  },
  // {
  //   label: 'แก้ไขข้อมูลจัดการรักษา',
  //   value: 'edit_treatment',
  //   section: 'treatment'
  // },
  {
    label: 'ยกเลิกใบเสร็จ',
    value: 'cancel_receipt',
    section: 'treatment'
  },
  {
    label: 'Reprint ใบเสร็จต้นฉบับ',
    value: 'reprint_receipt',
    section: 'treatment'
  },
  {
    label: 'ดูข้อมูลคนไข้',
    value: 'view_patient',
    section: 'patient'
  },
  {
    label: 'แก้ไขข้อมูลคนไข้',
    value: 'edit_patient',
    section: 'patient'
  },

  {
    label: 'ดูตารางนัดหมาย',
    value: 'view_schedule',
    section: 'booking'
  },
  {
    label: 'เพิ่ม/แก้ไขนัดหมาย',
    value: 'edit_schedule',
    section: 'booking'
  },
  {
    label: 'ดูข้อมูลนัดหมาย',
    value: 'view_booking',
    section: 'booking'
  },
  {
    label: 'แก้ไขสถานะนัดหมาย',
    value: 'edit_booking',
    section: 'booking'
  },

  {
    label: 'ขายสินค้า',
    value: 'edit_product',
    section: 'product'
  },
  {
    label: 'ดูข้อมูล CRM',
    value: 'view_crm',
    section: 'crm'
  },
  {
    label: 'เปลี่ยนสถานะ CRM',
    value: 'edit_crm',
    section: 'crm'
  },
  {
    label: 'ดูตารางลงตรวจแพทย์',
    value: 'view_doctor_schedule',
    section: 'doctor_schedule'
  },
  {
    label: 'แก้ไขตารางลงตรวจแพทย์',
    value: 'edit_doctor_schedule',
    section: 'doctor_schedule'
  },
  {
    label: 'ดูข้อมูล Lab',
    value: 'view_lab',
    section: 'lab'
  },
  {
    label: 'แก้ไขข้อมูล Lab',
    value: 'edit_lab',
    section: 'lab'
  },
  {
    label: 'ดูรายงาน Lab สาขา',
    value: 'view_lab_report_branch',
    section: 'lab'
  },
  {
    label: 'ดูรายงาน Lab บัญชี',
    value: 'view_lab_report_account',
    section: 'lab'
  },
  {
    label: 'ดูรายงาน Lab แพทย์',
    value: 'view_lab_report_doctor',
    section: 'lab'
  },
  {
    label: 'ดู/เพิ่มแบบฟอร์ม',
    value: 'view_medical_form',
    section: 'medical_form'
  },
  {
    label: 'ดู/เพิ่มข้อมูลบัญชี',
    value: 'view_accounting',
    section: 'accounting'
  },

  {
    label: 'จัดการทันตแพทย์',
    value: 'setting_doctor',
    section: 'setting'
  },
  {
    label: 'จัดการพนักงาน',
    value: 'setting_user',
    section: 'setting'
  },
  {
    label: 'จัดการ Contype',
    value: 'setting_contype',
    section: 'setting'
  },
  {
    label: 'จัดการสิทธิ์การใช้งาน',
    value: 'setting_role',
    section: 'setting'
  },
  {
    label: 'จัดการสาขา',
    value: 'setting_branch',
    section: 'setting'
  },

  {
    label: 'จัดการรายการรักษา',
    value: 'setting_treatment_item',
    section: 'setting'
  },
  {
    label: 'จัดการ Price List',
    value: 'setting_price_list',
    section: 'setting'
  },
  {
    label: 'จัดการหมวดการรักษา',
    value: 'setting_treatment_category',
    section: 'setting'
  },
  {
    label: 'การจัดการโปรโมชั่น',
    value: 'setting_promotion',
    section: 'setting'
  },
  {
    label: 'จัดการบริษัทแล็บ',
    value: 'setting_lab_company',
    section: 'setting'
  },
  {
    label: 'เข้าถึงได้ทุกสาขา',
    value: 'switch_branch',
    section: 'setting'
  },
]

const usersColumns: any = (onUpdate) => ([
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ชื่อตำแหน่ง' className='min-w-125px' />,
    accessor: 'name',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='สิทธิ์' className='min-w-125px' />,
    accessor: 'permissions',
    Cell: ({value, row})=> row.original.isSuperAdmin? 'All': value?.length >= 8 ?  `${value.length} สิทธ์ิ`:
    (value||[]).map((item)=>_.find(permissions, ['value', item])?.label||'n/a').join(', ')
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    accessor: 'isSuperAdmin',
    Cell: ({...props}) => !props.value && <UserActionsCell onUpdate={onUpdate} id={props.data[props.row.index].id} />,
  },
])

export {usersColumns}
