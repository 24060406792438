/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import api from '../../utils/Api'
import {numberFormat} from '../../utils/Functions'
import { Bar } from 'react-chartjs-2';
import {Container, Row, Col, Form, Button, InputGroup,Card, Table} from 'react-bootstrap'
import DatePicker from '../../components/DatePicker'
import _ from 'lodash'
import Select from 'react-select';

function fillRanking(data: any) {
  // Step 1: Create an array of prices with their original indices
  const indexedPrices = data.map((price: any, index: number) => ({ price, index }));

  // Step 2: Sort the array by price in descending order
  indexedPrices.sort((a: any, b: any) => b.price - a.price);

  // Step 3: Assign ranks based on the sorted array
  const ranks = new Array(data.length);
  indexedPrices.forEach((item: any, rank: number) => {
    ranks[item.index] = rank + 1;  // Rank starts from 1 (for highest price)
  });

  return ranks
}

const IncomeByBranch: FC = () =>{
  const [chart, setChart] = useState<any>({datasets: [], labels: []})
  const [filter, setFilter] = useState<any>({startDate: new Date, endDate: new Date})
  useEffect(()=>{
    if (!filter.startDate || !filter.endDate) return

    api.request('dashboard/recall-rate', (r,s)=>{
      setChart(r)
    }, {
      startDate: moment(filter.startDate).toISOString(),
      endDate: moment(filter.endDate).toISOString(),
      type: filter.type
    })
  }, [filter])

  const data = {
    labels: chart.labels, // X-axis labels
    datasets: chart.datasets,
  };

  const ranking = fillRanking(chart.datasets[2]?.data || [])

  return  (
    <Row className="dashboard__item-container">
      <Col xs={12} className="d-flex justify-content-end align-items-center">
        <Select
          className="me-5 w-150px mb-3"
          classNamePrefix="react-select"
          options={[{
            label: 'Recall ทั้งหมด',
            value: ''
          }, {label: 'Recall Dr. Note', value: 'dr_recall'}, {label: 'Recall จากระบบ', value: 'system_recall'}]}
          name="gender"
          defaultValue={{  label: 'Recall ทั้งหมด',
            value: ''}}
          onChange={(opt)=>{
            setFilter({...filter, type: opt?.value})
          }}
        />
        <div className="dashboard__date">
          <DatePicker onChange={([startDate, endDate]: any)=>{
            setFilter({...filter, startDate, endDate})
          }}
          startDate={filter.startDate}
          endDate={filter.endDate}
          dateFormat="dd/MM/yyyy"
          selectsRange
          />
        </div>
      </Col>
      <Col sm={12} md={9}>
        <h3 className="text-center">Recall Rate</h3>
        <Bar data={data} />
      </Col>
      <Col sm={12} md={3}>
        <Table bordered responsive className="dashboard__table--income-by-branch dataTable">
          <thead>
            <tr>
              <th>Branch</th>
              <th>จำนวนลูกค้ามาตาม Recall/ทั้งหมด</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            { chart.labels.map((code: string, index: number)=>(
              <tr key={index}>
                <td style={{width: 50}}>{code}</td>
                <td>{chart.datasets[0]?.data[index] || 0}/{chart.datasets[1]?.data[index] || 0}</td>
                <td>{chart.datasets[1]?.data[index]?`${Math.round(chart.datasets[0]?.data[index]*100/chart.datasets[1]?.data[index])}%`:''}</td>
              </tr>
            ))
            }
          </tbody>
        </Table>
      </Col>
    </Row>

  )
}


export default IncomeByBranch
