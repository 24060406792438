import React, {useState, useEffect, forwardRef} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import th from '@fullcalendar/core/locales/th';
import {Table} from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {default as BSModal} from 'react-bootstrap/Modal';
import moment from 'moment'
import doctorTypes from '../../apps/setting/doctor/data/DoctorTypes'
import api from '../../../utils/Api'
import _ from 'lodash'
import avatar from '../../../../_metronic/assets/avatar.png'
import 'moment/locale/th';
moment.locale('th');

const colors = [
  "#FFB74D",  // Light Orange
  "#42A5F5",  // Light Blue
  "#66BB6A",  // Light Green
  "#FFCC80",  // Lighter Amber
  "#D81B60",  // Light Pink
  "#4FC3F7",  // Light Cyan
  "#FF7043",  // Light Red-Orange
  "#4DB6AC",  // Light Teal
  "#81C784",  // Light Medium Green
  "#FFB300",  // Light Amber
  "#E91E63",  // Bright Pink
  "#9C27B0",  // Purple
  "#64B5F6",  // Light Sky Blue
  "#FF8A80",  // Light Coral (replacement for Soft Red)
  "#7986CB",  // Light Indigo
  "#00BCD4",  // Light Turquoise
  "#DCE775",  // Light Lime
  "#FF8A65",  // Light Deep Orange
  "#80CBC4",  // Light Aquamarine
  "#AB47BC"   // Light Deep Purple
]

export const cellHeight = 200;

const DayPopover = React.forwardRef((props: any, ref) => {
  const {data, date, onUpdate, onEdit, time} = props

  let dateStr = moment(date).locale('th').format('LL')
  const match = dateStr.match(/\d{4}/)
  const year = match ? parseInt(match[0]) + 543 : 0

  if (match) dateStr = dateStr.replace(match[0], year.toString())

  return (
    <>
    <Popover ref={ref} {...props}>
      <Popover.Header as="h3">
          <strong className="fs-3">{dateStr}</strong>
      </Popover.Header>
      <Popover.Body>
        {
          data.map((item: any, index: number)=>{

            return <div key={index}>
              <div className="d-flex" >
                <img style={{width: 80}} src={item.doctor.avatar?api.url.baseApi + item.doctor.avatar.url : avatar} />
                <div className="ms-3" >
                  <div style={{lineHeight: 0.9}} className="text-primary"><strong>{index+1}. {item.doctor.fullName}</strong></div>
                  <div style={{lineHeight: 0.9}}>{_.find(doctorTypes, ['value', item.doctor.doctorType])?.label}</div>
                  <div className="d-flex align-items-center"><i className="fa-regular d-none d-sm-block fa-clock text-dark me-2" />{
                    data?.startTime?.slice(0, -3) + '-' + data?.endTime?.slice(0, -3)
                  }</div>
                  <div className="text-decoration-underline text-primary">
                    <a target="_blank" href={`https://ldcdental.com/dentist/${item.doctor.prename.replace('.','')}-${item.doctor.firstName}-${item.doctor.lastName}`} >
                    ดูข้อมูลหมอเพิ่มเติม</a></div>
                </div>
            </div>
            <hr />
            </div>
          })
        }
      </Popover.Body>
    </Popover>
    </>
  )
})

export const DoctorSchedule = forwardRef(function({data, onDateClick, onEventClick}: any, ref: any) {
    const colorDoctors: Array<number> = []

    const events = data.map((props: any)=>{
      const {doctor, room, startTime, endTime, date} = props

      const index = colorDoctors.indexOf(doctor.id)
      let color;

      if (index > -1) {
        color = colors[index] || colors[0]
      } else {
        colorDoctors.push(doctor.id)
        color = colors[colorDoctors.length-1] || colors[0]
      }

      return ({
        title: room.branch.name,
        date: moment(date).format('YYYY-MM-DD'),
        color: 'transparent',
        extendedProps: {
          roomName: ``,
          data: props
        }
      })
    })

    return (
        <FullCalendar
          viewClassNames="iframe-schedule"
          //contentHeight={'auto'}
          ref={ref}
          locale={th}
          firstDay={0}
          plugins={[ dayGridPlugin, interactionPlugin ]}
          initialView="dayGridMonth"
          //headerToolbar={false}
          // dateClick={({date})=>{
          //   if (!can('edit_doctor_schedule')) return
          //
          //   if (onDateClick)
          //   onDateClick(date)
          // }}
          fixedWeekCount={false}
          eventOrder={(a:any,b:any)=>{
            if (a.roomName === b.roomName) {
              if (a.data.startTime > b.data.startTime) {
                return 1
              }

              return -1
            }

            return a.roomName > b.roomName ? 1 : -1
          }}
          eventClick={({event})=>{
            if (onEventClick) {
              onEventClick(event.extendedProps.data)
            }
          }}
          eventContent={({event})=>
          <div className="px-1 cursor-pointer border rounded border-primary" style={{color: 'black'}}>
            <div className="row-edge-center">
              <strong className="fs-4 text-primary event-title"><i className="fa fa-shop d-none d-sm-inline-block text-dark me-2" />{event.title}</strong>
              <strong className="fs-4">{event.extendedProps.roomName}</strong>
            </div>
              <div style={{whiteSpace: 'break-spaces'}} className="d-flex align-items-center fs-4 event-title"><i className="fa-regular d-none d-sm-block fa-clock text-dark me-2" />{
                event.extendedProps.data?.startTime?.slice(0, -3) + '-' + event.extendedProps.data?.endTime?.slice(0, -3)
              }</div>
          </div>}
          events={events}
        />
    )
})

export const BranchSchedule = forwardRef(function({data, onDateClick, onEventClick}: any, ref: any) {
    const colorDoctors: Array<number> = []
    let dates: any = []
    const [show, setShow] = useState(false)
    const [event, setEvent] = useState({time: '', date: new Date})
    const events = data.filter(({date}: any)=>{
      if (dates.indexOf(moment(date).format('YYYY-MM-DD')) > -1) return false

      dates.push(moment(date).format('YYYY-MM-DD'))

      return true
    }).map((props: any)=>{
      const {doctor, room, startTime, endTime, date} = props

      const index = colorDoctors.indexOf(doctor.id)
      let color;

      if (index > -1) {
        color = colors[index] || colors[0]
      } else {
        colorDoctors.push(doctor.id)
        color = colors[colorDoctors.length-1] || colors[0]
      }

      return ({
        title: doctor.fullName,
        date: moment(date).format('YYYY-MM-DD'),
        color: 'transparent',
        extendedProps: {
          roomName: ``,
          doctorType: _.find(doctorTypes, ['value', doctor.doctorType])?.label,
          data: props
        }
      })
    })

    const getDate = () => {
      let dateStr = moment(event.date).locale('th').format('LL')
      const match = dateStr.match(/\d{4}/)
      const year = match ? parseInt(match[0]) + 543 : 0

      if (match) dateStr = dateStr.replace(match[0], year.toString())

      return dateStr
    }

    return (
      <>
        <FullCalendar
          viewClassNames="iframe-schedule"
          contentHeight={'auto'}
          ref={ref}
          locale={th}
          firstDay={0}
          plugins={[ dayGridPlugin, interactionPlugin ]}
          initialView="dayGridMonth"
          //headerToolbar={false}
          // dateClick={({date})=>{
          //   if (!can('edit_doctor_schedule')) return
          //
          //   if (onDateClick)
          //   onDateClick(date)
          // }}
          fixedWeekCount={false}
          eventOrder={(a:any,b:any)=>{
            if (a.roomName === b.roomName) {
              if (a.data.startTime > b.data.startTime) {
                return 1
              }

              return -1
            }

            return a.roomName > b.roomName ? 1 : -1
          }}
          eventClick={({jsEvent, event})=>{
            jsEvent.preventDefault();
            setEvent({time: event.extendedProps.data?.startTime?.slice(0, -3) + '-' + event.extendedProps.data?.endTime?.slice(0, -3), date: moment(event.startStr).toDate()})
            setShow(true)
          }}
          eventContent={({event})=>
          <div className="column-center">
            <div className="px-1 cursor-pointer" style={{color: 'black', fontSize: '0.9rem'}}>
              <div className="row-edge-center">
                <strong className="text-primary event-title"><i className="fa text-primary fa-user-doctor d-none d-sm-inline-block text-dark me-2" />{event.title}</strong>
              </div>
                <div style={{whiteSpace: 'break-spaces', lineHeight: 0.9}} className="d-flex align-items-center event-title">{
                  event.extendedProps.doctorType
                }</div>
            </div>
            <OverlayTrigger
            onToggle={()=>{
              // if (!show && hoverStats.current==='hover') hoverStats.current = ''

            }}
            trigger="click"  placement="auto" overlay={<DayPopover
              //time={event.extendedProps.data?.startTime?.slice(0, -3) + '-' + event.extendedProps.data?.endTime?.slice(0, -3)}
            data={ data.filter(({date}: any)=>(moment(date).format('YYYY-MM-DD')==moment(event.start).format('YYYY-MM-DD')))}
             date={event.start} />}
           >
            <i className="fa fa-circle-plus mt-5 fs-1  cursor-pointer text-primary" />
            </OverlayTrigger>
          </div>}
          events={events}
        />
        <BSModal restoreFocus={false} centered scrollable show={!!show} onHide={()=>setShow(false)}>
          <BSModal.Header closeButton>
            <h3>{getDate()}</h3>
          </BSModal.Header>
          <BSModal.Body >
          {
            data.filter(({date}: any)=>moment(date).format('YYYY-MM-DD')==moment(event.date).format('YYYY-MM-DD')).map((item: any, index: number)=>{

              return <div key={index}>
                <div className="d-flex align-items-start" >
                  <img style={{width: 100}} className="rounded" src={item.doctor.avatar?api.url.baseApi + item.doctor.avatar.url : avatar} />
                  <div className="ms-3" >
                    <div style={{lineHeight: 0.9}} className="text-primary"><strong>{index+1}. {item.doctor.fullName}</strong></div>
                    <div style={{lineHeight: 0.9}}>{_.find(doctorTypes, ['value', item.doctor.doctorType])?.label}</div>
                    <div className="d-flex align-items-center"><i className="fa-regular d-none d-sm-block fa-clock text-dark me-2" />{
                    item.startTime?.slice(0, -3)  + '-' +item.endTime?.slice(0, -3)
                    }</div>
                    <div className="text-decoration-underline text-primary">
                      <a target="_blank" href={`https://ldcdental.com/dentist/${item.doctor.prename.replace('.','')}-${item.doctor.firstName}-${item.doctor.lastName}`} >
                      ดูข้อมูลหมอเพิ่มเติม</a></div>
                  </div>
              </div>
              <hr />
              </div>
            })
          }
          </BSModal.Body>
        </BSModal>
      </>
    )
})
