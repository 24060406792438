import clsx from 'clsx'
import {useState, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import api from '../../../../app/utils/Api'
import Select from 'react-select';
import {useAuth}  from '../../../../app/modules/auth/core/Auth'
import {reactSelectValue, reactSelectMultiValue} from '../../../../app/utils/Helper'
import _ from 'lodash'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {config} = useLayout()
  const [options, setOptions] = useState({ branches: [] })
  const [profile, setProfile] = useState<any>({})
  const {can, currentUser} = useAuth()

  useEffect(()=>{
    if (!can('switch_branch')) return

    api.request('branch', (r)=>{
      setOptions((o)=>({...o, branches: r.map(({name, id}: any)=>({label: name, value: id}))}))
    }, {forDropdown: true})
    api.request('profile', (r)=>{
      setProfile(r)
    })
  }, [])

  return (
    <div className='app-navbar flex-shrink-0'>
      {/*<div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>*/}
      { can('switch_branch') &&
      <div className="app-navbar-item">
        <Select
          classNamePrefix="react-select"
          options={options.branches}
          name="activeBranchId"
          placeholder="เลือกสาขา"
          onChange={(v: any)=>{
            api.request('user/'+profile.id, (r)=>{
              window.location.reload()
            }, api.form({
                ..._.pick(profile, ['firstName', 'lastName', 'email', 'roleId']),
                activeBranchId: v.value
            }), 'PATCH')
          }}
          value={reactSelectValue(options.branches, profile.activeBranchId)}
        />
      </div>
      }

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <a href="#" onClick={(e)=>e.preventDefault()}>{currentUser?.firstName} {currentUser?.lastName}</a>
          <i style={{fontSize: '0.75rem'}} className="fa fa-chevron-down text-primary ms-1" />
        </div>
        <HeaderUserMenu />
      </div>


      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
