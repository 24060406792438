/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import api from '../../utils/Api'
import {numberFormat} from '../../utils/Functions'
import { Bar } from 'react-chartjs-2';
import {Container, Row, Col, Form, Button, InputGroup,Card, Table} from 'react-bootstrap'
import DatePicker from '../../components/DatePicker'
import _ from 'lodash'

function sortByArray(array: any, arrayOrder: any) {
  let sortedArray = array
  .map((item: any, index: number) => ({ item, sortOrder: arrayOrder[index] })) // Create pairs of (item, sortOrder)
  .sort((a: any, b: any) => a.sortOrder - b.sortOrder)             // Sort based on sortOrder
  .map((pair: any) => pair.item);

  return  sortedArray
}

function fillRanking(data: any) {
  // Step 1: Create an array of prices with their original indices
  const indexedPrices = data.map((price: any, index: number) => ({ price, index }));

  // Step 2: Sort the array by price in descending order
  indexedPrices.sort((a: any, b: any) => b.price - a.price);

  // Step 3: Assign ranks based on the sorted array
  const ranks = new Array(data.length);
  indexedPrices.forEach((item: any, rank: number) => {
    ranks[item.index] = rank + 1;  // Rank starts from 1 (for highest price)
  });

  return ranks
}

const IncomeByBranch: FC = () =>{
  const [chart, setChart] = useState<any>({datasets: [], labels: []})
  const [filter, setFilter] = useState<any>({startDate: new Date, endDate: new Date})
  const [sort, setSort] = useState('order')

  const fetch = () => {
    api.request('dashboard/income-by-branch', (r,s)=>{
      if (sort == 'order') {
      setChart(r)
      } else {
        sortByRank(r)
      }
    }, {
      startDate: moment(filter.startDate).toISOString(),
      endDate: moment(filter.endDate).toISOString()
    })
  }

  useEffect(()=>{
    if (!filter.startDate || !filter.endDate) return
    fetch()
  }, [filter, sort])

  const data = {
    labels: chart.labels, // X-axis labels
    datasets: chart.datasets,
  };

  const ranking = fillRanking(chart.datasets[2]?.data || [])

  const sortByRank = (chart: any) => {
    const rankChart = {...chart}
    if (!rankChart.datasets[0]) return

    const ranking = fillRanking(chart.datasets[2]?.data || [])

    rankChart.labels = sortByArray(rankChart.labels, ranking)
    rankChart.datasets[0].data = sortByArray(chart.datasets[0]?.data, ranking)
    rankChart.datasets[1].data = sortByArray(chart.datasets[1]?.data, ranking)
    rankChart.datasets[2].data = sortByArray(chart.datasets[2]?.data, ranking)

    setChart(rankChart)
  }

  return  (
    <Row className="dashboard__item-container">
      <Col xs={12} className="d-flex justify-content-end">
        <div className="dashboard__date">
          <DatePicker onChange={([startDate, endDate]: any)=>{
            setFilter({startDate, endDate})
          }}
          startDate={filter.startDate}
          endDate={filter.endDate}
          dateFormat="dd/MM/yyyy"
          selectsRange
          />
        </div>
      </Col>
      <Col sm={12} md={9}>
        <h3 className="text-center">Income all branches</h3>
        <Bar data={data} />
      </Col>
      <Col sm={12} md={3}>
        <Table bordered responsive className="dashboard__table--income-by-branch dataTable">
          <thead>
            <tr>
              <th onClick={()=>{
                setSort('order')
                fetch()
              }}>Branch <i className={"fa fa-caret-down " + (sort === 'order'?'text-white':'') + " ms-1"}></i></th>
              <th>Dental</th>
              <th>Product</th>
              <th>Total</th>
              <th onClick={()=>{
                sortByRank(chart)
              setSort('rank')
            }}>Rank  <i className={"fa fa-caret-down " + (sort === 'rank'?'text-white':'') + " ms-1"}></i></th>
            </tr>
          </thead>
          <tbody>
            { chart.labels.map((code: string, index: number)=>(
              <tr key={index}>
                <td style={{width: 70}}>{code}</td>
                <td>{numberFormat(Math.round(chart.datasets[0]?.data[index] || 0), true)}</td>
                <td>{numberFormat(Math.round(chart.datasets[1]?.data[index] || 0), true)}</td>
                <td>{numberFormat(Math.round(chart.datasets[2]?.data[index] || 0), true)}</td>
                <td>{ranking[index]}</td>
              </tr>
            ))
            }
            <tr>
              <td><strong>Total</strong></td>
              <td>{numberFormat(_.sumBy(chart.datasets[0]?.data || [], (r: any)=>Math.round(r||0)), true)}</td>
              <td>{numberFormat(_.sumBy(chart.datasets[1]?.data || [], (r: any)=>Math.round(r||0)), true)}</td>
              <td>{numberFormat(_.sumBy(chart.datasets[2]?.data || [], (r: any)=>Math.round(r||0)), true)}</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>

  )
}


export default IncomeByBranch
