const doctorTypes = [
    {
        label: 'ทันตกรรมจัดฟัน Orthodontics',
        value: 'orthodontics',
    },
    {
        label: 'ทันตกรรมรักษารากฟัน Endodontics',
        value: 'wndodontics',
    },
    {
        label: 'ทันตกรรมทั่วไป Dental General Practice',
        value: 'dental_general_practice',
    },
    {
        label: 'ทันตกรรมเด็ก Pedodontics',
        value: 'pedodontics',
    },
    {
        label: 'ทันตกรรมประดิษฐ์-ใส่ฟัน Prosthodontics',
        value: 'prosthodontics',
    },
    {
        label: 'ทันตกรรมโรคเหงือก และปริทันต์ Periodontics',
        value: 'periodontics',
    },
    {
        label: 'ทันตกรรมศัลยกรรม Surgery',
        value: 'switchurgery',
    },
    {
        label: 'ทันตกรรมบดเคี้ยว Occlusion',
        value: 'occlusion',
    },
    {
        label: 'ทันตกรรมรากฟันเทียม Implant',
        value: 'implant',
    },
    {
        label: 'ทันตกรรมทั่วไปพิเศษ Super GP',
        value: 'super_gp',
    },
    {
        label: 'ทันตกรรมบูรณะ เพื่อความสวยงาม Operative',
        value: 'operative',
    },
    {
        label: 'เวชศาสตร์ช่องปาก Oral-med',
        value: 'oral_med',
    },
];

export default doctorTypes
