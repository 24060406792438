/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import api from '../../utils/Api'
import {numberFormat} from '../../utils/Functions'
import { Line } from 'react-chartjs-2';
import {Container, Row, Col, Form, Button, InputGroup,Card, Table} from 'react-bootstrap'
import DatePicker from '../../components/DatePicker'
import {reactSelectValue, reactSelectMultiValue} from '../../utils/Helper'
import _ from 'lodash'
import Select from 'react-select';
import autocolors from 'chartjs-plugin-autocolors';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function fillRanking(data: any) {
  // Step 1: Create an array of prices with their original indices
  const indexedPrices = data.map((price: any, index: number) => ({ price, index }));

  // Step 2: Sort the array by price in descending order
  indexedPrices.sort((a: any, b: any) => b.price - a.price);

  // Step 3: Assign ranks based on the sorted array
  const ranks = new Array(data.length);
  indexedPrices.forEach((item: any, rank: number) => {
    ranks[item.index] = rank + 1;  // Rank starts from 1 (for highest price)
  });

  return ranks
}

const AllCustomer = ({branches}: any) =>{

  const [chart, setChart] = useState<any>({datasets: [], labels: []})
  const [filter, setFilter] = useState<any>({startDate: new Date, endDate: new Date, type: 'sales'})

  const [options, setOptions] = useState<any>({branches: []})

  useEffect(()=>{
    api.request('contype', (r)=>{
      setOptions((o: any)=>({...o, contypes: r.map(({nameTh, id}: any)=>({label: nameTh, value: id}))}))
    }, {forDropdown: true})
    //generateZipWithCSV()
  }, [])

  useEffect(()=>{
    if (!filter.startDate || !filter.endDate) return

    api.request('dashboard/all-customer', (r,s)=>{
      setChart(r)
    }, {
      ...filter,
      startDate: moment(filter.startDate).toISOString(),
      endDate: moment(filter.endDate).toISOString(),
    })
  }, [filter])

  const data = {
    labels: chart.labels, // X-axis labels
    datasets: chart.datasets,
  };

  const ranking = fillRanking(chart.datasets[2]?.data || [])

  return  (
    <Row className="dashboard__item-container">
      <Col xs={12} className="d-flex justify-content-end align-items-center">
        <Select
          className="me-5 w-150px mb-3"
          classNamePrefix="react-select"
          options={options.contypes}
          placeholder="All contype"
          isClearable
          name="type"
          onChange={(opt: any)=>{
            setFilter({...filter, contypeId: opt?.value})
          }}
        />
        <Select
          className="mb-3 me-3"
          classNamePrefix="react-select"
          options={branches}
          placeholder="All branches"
          isClearable
          onChange={(v: any)=>{
            setFilter({...filter, branchId: v?.value || ''})
          }}
          value={reactSelectValue(branches, filter.branchId)}
        />
        <div className="dashboard__date">
          <DatePicker onChange={([startDate, endDate]: any)=>{
            setFilter({...filter, startDate, endDate})
          }}
          startDate={filter.startDate}
          endDate={filter.endDate}
          dateFormat="dd/MM/yyyy"
          selectsRange
          />
        </div>
      </Col>
      <Col sm={12} md={12}>
        <h3 className="text-center">Customers</h3>
        <Line data={data} plugins={[autocolors, ChartDataLabels]} options={{
          plugins: {
            colors: {
              enabled: true
            },
            autocolors: {
              enabled: false
            },
            // legend: {
            //   display: false // Disable the legend completely
            // },
           //  autocolors: {
           //   enabled: true,
           //   mode: 'data',
           //   offset: 0
           // },
           datalabels: {
             anchor: 'end',
             align: 'end',
             backgroundColor: function(context) {
               if (typeof context.dataset.backgroundColor === 'string')
               return context.dataset.backgroundColor

               return null
             },
             borderRadius: 4,
             color: 'white',
             font: {
               weight: 'bold'
             },
             formatter: Math.round,
             padding: 6
           }
         },
         // scales: {
         //   y1: {
         //     position: "right",
         //     afterBuildTicks: (axis) => {
         //
         //       axis.ticks = axis.chart.scales.y?[...axis.chart.scales.y.ticks]:[];
         //       axis.min = axis.chart.scales.y?.min || 0;
         //       axis.max = axis.chart.scales.y?.max || 1;
         //     },
         //  },
         // }
         //indexAxis: 'y'
        }} />
      </Col>
    </Row>

  )
}


export default AllCustomer
