/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import api from '../../../../../app/utils/Api'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth}  from '../../../../../app/modules/auth/core/Auth'
import {permissions} from '../../../../../app/modules/apps/setting/role/table/columns/_columns'
import Modal from '../../../../../app/components/Modals'
import html2canvas from 'html2canvas';
import {Button, Row, Col, Form, Table} from 'react-bootstrap'

export const SidebarMenuMain = () => {
  const intl = useIntl()
  const {can, currentUser} = useAuth()
  const reportPerms = permissions.filter(({section})=>section === 'report').map(({value})=>value)
  const [showReport, setShowReport] = useState(false)
  const [form, setForm] = useState<any>({type: 'image', attachmentBase64: '', description: '', fileName: ''})
  const [uploading, setUploading] = useState(false)
  const [profile, setProfile] = useState<any>({})

  useEffect(()=>{
    if (showReport) {
      setForm({...form, type: 'image', attachmentBase64: ''})
    }
  }, [showReport])

  useEffect(()=>{
    api.request('profile', (r)=>{
      setProfile(r)
    })
  }, [])

  const handleCapture = async () => {
      const element = document.getElementById('kt_app_body');
      if (element) {
        const canvas = await html2canvas(element, {
        ignoreElements: (el) => {
          return el.classList.contains('fade') || el.id === 'kt_app_header' || el.id === 'kt_app_sidebar'
          || el.id === 'report-problem-floating-button'
          || el.tagName.toLowerCase() === 'noscript';
        }
      });
        const imageDataUrl = canvas.toDataURL('image/png');
        setForm({...form, attachmentBase64: imageDataUrl})

        // const response = await fetch(imageDataUrl);
        // const blob = await response.blob();
        //
        // // Create FormData and append the blob
        // const formData = new FormData();
        // formData.append('image', blob, 'captured-image.png');
        //;
      }
  }

  return (
    <>
      { can('view_dashboard') && !profile.roomId &&
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      }
      { can(reportPerms) && !profile.roomId &&
      <SidebarMenuItem
        to='/report'
        icon='element-11'
        title={"รายงาน"}
        fontIcon='bi-app-indicator'
      />
      }
      {/*<SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>*/}
      { can(['view_dashboard', 'view_report'])  && !profile.roomId &&
      <div className='menu-item'>
        <hr />
      </div>
      }
      {/*<SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>*/}
      { can(['view_treatment', 'edit_treatment', 'cancel_receipt'])  &&
      <SidebarMenuItem
        to='/treatment'
        icon='syringe'
        title='จัดการรักษา'
        fontIcon='bi-layers'
      />
     }
       { can(['view_patient', 'edit_patient'])  && !profile.roomId &&
      <SidebarMenuItem
        to='/patient'
        icon='people'
        title='คนไข้'
        fontIcon='bi-layers'
      />
      }
      { can(['view_schedule', 'edit_schedule'])  &&
      <SidebarMenuItem
        to='/schedule'
        icon='calendar'
        title='ตารางนัดหมาย'
        fontIcon='bi-layers'
      />
      }
      { can(['view_booking', 'edit_booking'])  && !profile.roomId &&
      <SidebarMenuItem
        to='/appointment/tomorrow'
        icon='calendar-edit'
        title='จัดการนัดหมาย'
        fontIcon='bi-layers'
      />
      }
    { can('edit_product')  && !profile.roomId &&
      <SidebarMenuItem
        to='/product-sales'
        icon='basket'
        title='ขายสินค้า'
        fontIcon='bi-layers'
      />
      }
      { can(['view_crm', 'edit_crm'])  && !profile.roomId &&
      <SidebarMenuItem
        to='/crm'
        icon='user-square'
        title='CRM'
        fontIcon='bi-layers'
      />
      }
      { can(['edit_product', 'view_booking', 'edit_booking', 'view_schedule', 'edit_schedule',
        'view_crm', 'edit_crm', 'view_patient', 'edit_patient', 'view_treatment',
        'edit_treatment', 'cancel_receipt'
        ]) &&
      <div className='menu-item'>
        <hr />
      </div>
      }
     { can(['view_doctor_schedule', 'edit_dotor_schedule'])  && !profile.roomId &&
      <SidebarMenuItem
        to='/doctor-schedule'
        icon='calendar'
        title='ตารางลงตรวจแพทย์'
        fontIcon='bi-layers'
      />
      }
      { can(['view_lab', 'edit_lab', 'view_lab_report'])  && !profile.roomId &&
      <SidebarMenuItem
        to='/lab'
        icon='pulse'
        title='ห้องแล็บ'
        fontIcon='bi-layers'
      />
      }
      { can('view_medical_form')  && !profile.roomId &&
      <SidebarMenuItem
        to='/form'
        icon='document'
        title='แบบฟอร์ม'
        fontIcon='bi-layers'
      />
      }
      { can('view_accounting')  && !profile.roomId &&
      <SidebarMenuItem
        to='/accounting'
        icon='wallet'
        title='บัญชี'
        fontIcon='bi-layers'
      />
      }
      {!profile.roomId &&
      <div className='menu-item'>
        <hr />
      </div>
      }
      { !profile.roomId &&
      <SidebarMenuItem
        to='/handbook'
        icon='book'
        title='คู่มือการใช้งาน'
        fontIcon='bi-layers'
      />
     }
     { !profile.roomId &&
      <div className="menu-item">
        <a className="menu-link without-sub" onClick={(e)=>{
          e.preventDefault()
          setShowReport(true)
        }} href="#">
          <span className="menu-icon">
            <KTIcon iconName={'information'} className='fs-2' />
          </span>
          <span className="menu-title">แจ้งปัญหา</span>
        </a>
      </div>
      }
      { can('setting_', true)  && !profile.roomId &&
      <SidebarMenuItem
        to='/setting'
        icon='setting-2'
        title={can('switch_branch')?'การจัดการระบบ':'อื่นๆ'}
        fontIcon='bi-layers'
      />
      }
      {/*<div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>*/}
      <Modal
      id="report-problem"
      size="lg"
      show={showReport}
      centered
      centeredTitle
      onHide={()=>{
        setShowReport(false)

      }}
      title={"แจ้งปัญหาการใช้งาน"}>
        <Form.Group controlId="description">
          <Form.Label>อธิบายปัญหาที่พบ</Form.Label>
          <Form.Control as="textarea" rows={8} onChange={(e)=>setForm({...form, description: e.target.value})} />
        </Form.Group>
        <Form.Group className="mt-5">
            <div><Form.Label>ไฟล์ที่ต้องการแนบ</Form.Label></div>
            <Form.Check
              type="radio"
              label="ภาพหน้าจอ"
              inline
              name="attachmentType"
              id="image"
              defaultChecked
              onChange={(e)=>setForm({...form, type: 'image'})}
              value={'image'}
            />
            <Form.Check
              type="radio"
              label="แนบไฟล์"
              inline
              name="attachmentType"
              id="file"
              value={'file'}
              onChange={(e)=>setForm({...form, type: 'file'})}
              className="ms-6"
            />
        </Form.Group>
        <hr />
        {
          form.type === 'image' ?
          <>
          <Button className="mb-3" variant="secondary" size="sm" onClick={handleCapture}>ถ่ายภาพหน้าจอ</Button>
            {
              !!form.attachmentBase64 && <img src={form.attachmentBase64} style={{maxWidth: '100%'}} />
            }
          </>
          :
          <input type="file" accept="video/mp4,video/x-m4v,video/*,image/*" onChange={(e)=>{
            const file = e.target.files?.[0]; // Get the first file

             if (file) {
               const sizeInMB = file.size / (1024 * 1024);

               if (sizeInMB > 25) {
                 alert('ไฟล์ต้องมีขนาดไม่เกิน 25MB')
                 return
               }

               const reader = new FileReader();

               // Read the file as a data URL (Base64)
               reader.readAsDataURL(file);

               reader.onloadend = () => {
                setForm({...form, fileName: file.name, attachmentBase64: reader.result || ''});
               };

               reader.onerror = (error) => {
                 console.error('Error reading file:', error);
               };
             } else {
               setForm({...form, fileName: '', attachmentBase64: ''})
             }
          }} />
        }
        <div className="row-center mt-5"><Button disabled={uploading} onClick={()=>{
          const attachmentBase64 = form.attachmentBase64.split(',')[1] || ''
          setUploading(true)
          api.request('report-problem', (r, s)=>{
            setUploading(false)
            if (!s) return
            setShowReport(false)
          }, api.form({...form, attachmentBase64, fileName: form.type == 'image'?'screencapture.png':form.fileName}), 'POST')
        }}>{uploading?`กำลังส่งข้อมูล...`:`แจ้งปัญหา`}</Button></div>
      </Modal>
      <div id="report-problem-floating-button" onClick={()=>setShowReport(true)}>
        <i className="fa fa-bullhorn fs-2 text-white"></i>
      </div>
    </>
  )
}
